import React from "reactn";
import axios from "axios";
import { toast } from "react-toastify";
import dateFns, {
  format,
  addMonths,
  addWeeks,
  getDay,
  isAfter,
  isToday,
  addDays,
  subDays
} from "date-fns";
import "../../../assets/css/componentSpecificCss/invoiceBuilder.css";
import "../../../assets/css/componentSpecificCss/orderReceipt.css";
import { OrderReceiptTile } from "../../../assets";
import { useOrderData, useOrderCalculatedData } from "./OrderReceiptUtil";
import DatePicker from "react-datepicker";
import AddInvoiceLineItemModal from "../../../components/modals/AddInvoiceLineItemModal";
import AddDiscountModal from "../../../components/modals/AddDiscountModal";
import EditDiscountModal from "../../../components/modals/EditDiscountModal";
import CreateDiscountModal from "../../../components/modals/CreateDiscountModal";
import AddTotalDiscountModal from "../../../components/modals/AddTotalDiscountModal";
import CancelConfirmationModal from "../../../components/modals/CancelConfirmationModal";
import SendInvoiceEmailModal from "../../../components/modals/SendInvoiceEmailModal";
import ConfirmSendEmailModal from "../../../components/modals/ConfirmSendEmailModal";
import EditInvoiceQuantityModal from "../../../components/modals/EditInvoiceQuantityModal";
import EditItemModal from "../../../components/modals/EditItemModal";

import { withRouter } from "react-router-dom";

import { useInvoiceItemData } from "./InvoiceBuilderUtil";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Edit, Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import ViewPartyAddonsModal from "../../../components/modals/ViewPartyAddonsModal";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
class NewInvoiceView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openViewPartyAddonsModal: false,
      data: null,
      invoiceDate: new Date(),
      invoiceDueDate: null,
      userPaidDate: null,
      serviceStartDate: new Date(),
      serviceEndDate: addMonths(new Date(), 1),
      invoice_status: "Draft",
      customer: [
        {
          displayName: "",
          email: "",
          address: "",
          city: "",
          province: "",
          country: "",
          postalCode: "",
          homePhone: ""
        }
      ],
      invoiceDetails: [],
      bodyData: [],
      footData: [],
      anchorEl: null,
      anchorE2: null,
      openCreateDiscountModal: false,
      openAddTotalDiscountModal: false,
      finalTotal: 0,
      subTotal: 0,
      discountTotal: 0,
      message: "",
      taxCode: "",
      referenceNumber: "N/A",
      refetch: 0
    };

    this.headings = [
      { label: "Item Name", style: { textAlign: "left" } },
      { label: "Rate", style: { textAlign: "right" } },
      { label: "Discount", style: { textAlign: "right" } },
      { label: "Quantity", style: { textAlign: "right" } },
      { label: "Amount", style: { textAlign: "right" } },
      { label: "", style: { textAlign: "right" } }
    ];

    this.body = [
      [
        { value: "Music School" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "$1000" }
      ],
      [
        { value: "Coupon: 10% Off" },
        { value: "" },
        { value: "-$100" },
        { value: "" },
        { value: "" }
      ],
      [
        { value: "Sub-Total", style: { fontWeight: "bold", color: "black" } },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "$900", style: { fontWeight: "bold", color: "black" } }
      ]
    ];

    this.foot = [
      [
        { value: "Total", style: { fontWeight: "bold", color: "black" } },
        { value: "" },
        { value: "$980", style: { fontWeight: "bold", color: "black" } }
      ]
    ];
  }

  updateInvoiceDate = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;
    const res = await axios.post(ep, {
      id: this.props.match.params.id,
      next_invoice_date: this.state.next_invoice_date
    });
    console.log("res Date Updates", res);
    if (res.data.success) {
      console.log("updated");

      this.fetchInitialData();
    }
  };

  getNextDueDate() {
    const billingCycle = this.state.data.recurring_frequency;
    let dueDate = new Date(this.state.data.next_invoice_date);

    switch (billingCycle) {
      case "week":
        return addDays(dueDate, 1);

      case "bi-week":
        return addDays(dueDate, 2);

      case "month":
        return addDays(dueDate, 3);

      case "membership-month":
        dueDate = addMonths(dueDate, this.state.data.membership_term);
        return subDays(dueDate, 1);
      
      case "custom":
        return addDays(dueDate, 3);

      default:
        console.error("Invalid billing cycle");
        return dueDate;
    }
  }

  async fetchInitialData() {
    const ep = `${process.env.REACT_APP_API}/partners/orders/invoice/${this.props.match.params.id}`;
    this.setGlobal({ loading: true });
    const results = await axios.get(ep);
    console.log("results", results);

    try {
      const ep2 = `${process.env.REACT_APP_API}/partners/stripe/taxCode`;
      const taxCode = await axios.get(ep2);
      console.log("taxCode", taxCode);

      this.setState({ taxCode: taxCode.data.data });
    } catch (e) {
      console.error(e);
    }

    if (results.data.success) {
      let body, foot;
      const {
        couponAmount,
        promotionAmount,
        creditCardFeeAmount,
        depositAmount,
        amount,
        itemAmount,
        giftDiscount,
        taxAmount,
      } = results.data.data;
      if (
        couponAmount == "0" &&
        promotionAmount == "0" &&
        creditCardFeeAmount == "0" &&
        depositAmount == "0" &&
        itemAmount == "0" &&
        giftDiscount == "0" &&
        taxAmount == "0"
      ) {
        console.log("Calculated Data");
        const { bodyData, footData } = useOrderData(
          results.data.data,
          this.props.match.params.isDeposit
        );

        console.log("bodyData 1", bodyData);
        console.log("footData 1", footData);

        body = bodyData;
        foot = footData;
      } else {
        console.log("Order Data");
        let coupon = null;
        if (results.data.data.couponId) {
          const couponEP = `${process.env.REACT_APP_API}/partners/stripe/coupon/${results.data.data.couponId}`;
          coupon = await axios.get(couponEP);
          console.log("coupon", coupon);
        }
        const { bodyData, footData } = useOrderData(
          results.data.data,
          this.props.match.params.isDeposit,
          coupon?.data?.data?.coupon_name
        );
        console.log("bodyData 2", bodyData);
        console.log("footData 2", footData);

        body = bodyData;
        foot = footData;
      }

      this.setState({
        bodyData: body,
        footData: foot,
        data: results.data.data,
        customer: [results.data.data.customer],
        invoiceDate: new Date(results.data.data.createdAt),
        userPaidDate: results.data.data.userPaidDate
          ? new Date(results.data.data.userPaidDate)
          : null,
        invoiceDueDate: results.data.data.due_date
          ? new Date(results.data.data.due_date)
          : null
      });
    }
    if (this.state.data.type === "Recurring") {
      this.setGlobal({
        partnerPathway: [
          ...this.global.partnerPathway.slice(0, 1),
          { label: "Recurring Payments", to: "/recurring" },
          {
            label: this.state.data.displayName,
            to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
          }
        ]
      });
    } else {
      this.setGlobal({
        partnerPathway: [
          ...this.global.partnerPathway.slice(0, 1),
          { label: "Order Tracking", to: "/orders" },
          {
            label: this.state.data.displayName,
            to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
          }
        ]
      });
    }
    this.setGlobal({ loading: false });
    this.setState(
      {
        referenceNumber: `po${results.data.data.id}, uid${results.data.data.dsUserId}, ${results.data.data.stripe_transaction_id}`
      },
      () => console.log("reference number", this.state.referenceNumber)
    );
  }

  editLineItem = async items => {
    console.log("lineItem", items);

    this.setState({
      invoiceDetails: items
    });

    const { bodyData, footData, finalTotal, subTotal, discountTotal } =
      useInvoiceItemData(items, this.state.data);

    console.log("bodyData", bodyData);
    console.log("footData", footData);

    this.setState({ bodyData, footData, finalTotal, subTotal, discountTotal });

    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;
    const results = await axios.post(ep, {
      id: this.props.match.params.id,
      invoice_details: items,
      amount: finalTotal,
      itemAmount: Math.round(subTotal),
      discountAmount: discountTotal
    });

    console.log("results on DB", results);
    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  saveLineItem = async item => {
    console.log("lineItem", item);

    const id = this.state.invoiceDetails.length;

    let data = this.state.invoiceDetails;
    if (item.id) {
      // When an invoice line item is existing or newly created
      const lineData = {
        itemName: item.itemName,
        rate: Math.round(parseFloat(item.rate)),
        description: item.description,
        discountId: null,
        discount: null,
        quantity: item.quantity ? parseInt(item.quantity) : 1,
        amount: Math.round(parseFloat(item.rate)),
        id: id,
        serviceStartDate: null,
        serviceEndDate: null,
        pricingItemId: item.id
      };
      data = [...this.state.invoiceDetails, lineData];
      this.setState({
        invoiceDetails: data
      });
    } else {
      // When an invoice item is not saved on our tables
      const lineData = {
        itemName: item.itemName,
        rate: Math.round(parseFloat(item.rate)),
        description: item.description,
        discountId: null,
        discount: null,
        quantity: item.quantity ? parseInt(item.quantity) : 1,
        amount: Math.round(parseFloat(item.rate)),
        id: id,
        serviceStartDate: null,
        serviceEndDate: null
      };
      data = [...this.state.invoiceDetails, lineData];

      this.setState({
        invoiceDetails: data
      });
    }

    const { bodyData, footData, finalTotal, subTotal, discountTotal } =
      useInvoiceItemData(data, this.state.data);

    console.log("bodyData", bodyData);
    console.log("footData", footData);

    this.setState({ bodyData, footData, finalTotal, subTotal, discountTotal });

    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;
    const results = await axios.post(ep, {
      id: this.props.match.params.id,
      invoice_details: data,
      amount: finalTotal,
      itemAmount: Math.round(subTotal),
      discountAmount: discountTotal
    });

    console.log("results on DB", results);
    if (results.data.success) {
    }
    this.fetchInitialData();
  };

  componentDidMount() {
    this.fetchInitialData();
  }

  render() {
    console.log("this.props", this.props);
    console.log("this.state", this.state);
    console.log("this.global", this.global);

    if (this.state.customer.displayName === "") return null;
    // const { customer } = this.state;
    let customer;
    if (this.state.customer?.[0]) {
      // it could be [undefined]
      customer = this.state.customer;
    } else {
      customer = [this.state.data];
    }
    console.log("NewInvoiceView.jsx 182: customer", customer);

    let minNextDate;
    if (
      this.state.data?.next_invoice_date &&
      this.state.data?.recurring_frequency
    ) {
      const created = new Date(this.state.data.createdAt);
      switch (this.state.data.recurring_frequency) {
        case "week":
          minNextDate = addWeeks(new Date(created), 1);
          break;
        case "bi-week":
          minNextDate = addWeeks(new Date(created), 2);
          break;
        case "month":
          minNextDate = addMonths(new Date(created), 1);
          break;
        default:
          break;
      }
      minNextDate = subDays(minNextDate, 3);
    }
    console.log(minNextDate);

    return (
      <div className="admin">
        {this.state.openViewPartyAddonsModal && (
          <ViewPartyAddonsModal
            disabled={false}
            canSubUserEdit={true}
            onClose={() => {
              this.setState({ openViewPartyAddonsModal: false })
            }}
            refetch={async _ => {
              await this.fetchInitialData()
            }}
            updatePartyDetails={(partyDetails) => {
              // this.props.program.partyDetails = partyDetails
            }}
            applicationInfo={this.state.data?.customer}
            partyDetails={this.state.data?.customer?.partyDetails}
          />
        )}
        {this.state.openAddItemModal && (
          <AddInvoiceLineItemModal
            onClose={_ => {
              this.setState({ openAddItemModal: false });
            }}
            lineItem={e => this.saveLineItem(e)}
          />
        )}

        {this.state.openAddDiscountModal && (
          <AddDiscountModal
            selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails}
            onClose={_ => {
              this.setState({ openAddDiscountModal: false });
            }}
            openCreateDiscountModal={e =>
              this.setState({
                openCreateDiscountModal: e,
                openAddDiscountModal: false
              })
            }
            addedDiscount={e => this.fetchInitialData()}
          />
        )}

        {this.state.openCreateDiscountModal && (
          <CreateDiscountModal
            addedDiscount={e =>
              this.setState({
                openAddDiscountModal: true,
                openCreateDiscountModal: false
              })
            }
            onClose={_ => {
              this.setState({ openCreateDiscountModal: false });
            }}
          />
        )}

        {this.state.openEditDiscountModal && (
          <EditDiscountModal
            selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails}
            onClose={_ => {
              this.setState({ openEditDiscountModal: false });
            }}
            removeDiscount={_ => this.fetchInitialData()}
            editDiscount={e => this.fetchInitialData()}
          />
        )}

        {this.state.openAddTotalDiscountModal && (
          <AddTotalDiscountModal
            addedDiscount={e => this.fetchInitialData()}
            footData={this.state.footData}
            onClose={_ => {
              this.setState({ openAddTotalDiscountModal: false });
            }}
            subTotal={this.state.subTotal}
          />
        )}

        {this.state.openCancelConfirmationModal && (
          <CancelConfirmationModal
            confirmCancel={e => this.fetchInitialData()}
            id={this.props.match.params.id}
            onClose={_ => {
              this.setState({ openCancelConfirmationModal: false });
            }}
          />
        )}

        {this.state.openSendEmailModal && (
          <SendInvoiceEmailModal
            sendMessage={e =>
              this.setState({ message: e, openConfirmSendEmailModal: true })
            }
            data={this.state.data}
            onClose={_ => {
              this.setState({ openSendEmailModal: false });
            }}
            customer={this.state.customer}
          />
        )}

        {this.state.openConfirmSendEmailModal && (
          <ConfirmSendEmailModal
            data={this.state.data}
            message={this.state.message}
            sendMessage={e => this.setState({ openSendEmailModal: false })}
            onClose={_ => {
              this.setState({ openConfirmSendEmailModal: false });
              this.fetchInitialData();
            }}
          />
        )}

        {this.state.openEditQuantityModal && (
          <EditInvoiceQuantityModal
            selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails}
            onClose={_ => {
              this.setState({ openEditQuantityModal: false });
              this.fetchInitialData();
            }}
          />
        )}

        {this.state.openEditItemModal && (
          <EditItemModal
            selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails}
            onClose={_ => {
              this.setState({ openEditItemModal: false });
              // this.fetchInitialData();
            }}
            lineItem={e => {
              console.log("e", e);
              this.editLineItem(e);
            }}
          />
        )}

        <div className="container-fluid adminprofiles">
          <div style={{ paddingLeft: "0px" }} className="cont">
            <h1>{""}</h1>
          </div>

          <div
            className="invoice-receipt-container"
            style={{ marginBottom: "10px" }}
          >
            <div className="invoice-recipt-header-container">
              <div
                className="order-header-background"
                style={{
                  minHeight: 280,
                  backgroundColor: "white",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTopLeftRadius: "26px",
                  borderTopRightRadius: "26px"
                }}
              >

              {/* {this.state.data.logo && (
                <div className="order-logo-wrapper">
                  <LazyLoadImage
                    src={`${process.env.REACT_APP_DS_FILES_S3}/${this.state.data.logo}`}
                    alt="Org logo"
                    className="order-logo"
                  />
                </div>
              )} */}

                <div className="order-name-section">
                  <div className="invoice-name">
                    {this.global.organizationInfo.organization_title}
                  </div>
                  <div className="invoice-email">
                    {this.global.organizationInfo.address1}
                    {this.global.organizationInfo.address2
                      ? `, ${this.global.organizationInfo.address2}`
                      : ""}
                  </div>
                  <div className="invoice-email">
                    {this.global.organizationInfo.city}
                    {this.global.organizationInfo.province
                      ? `, ${this.global.organizationInfo.province}`
                      : ""}
                    {this.global.organizationInfo.country
                      ? `, ${this.global.organizationInfo.country}`
                      : ""}
                    {this.global.organizationInfo.postalcode
                      ? "\xa0\xa0" + this.global.organizationInfo.postalcode
                      : ""}
                  </div>
                  <div className="invoice-email">{this.global.dsUser.email}</div>
                  <div className="invoice-email">{this.global.dsUser.phone}</div>
                  {this.state.taxCode && this.state.taxCode !== "" && (
                    <div style={{ fontSize: "14px" }}>
                      <strong>Tax Code: </strong>
                      {this.state.taxCode}
                    </div>
                  )}
                </div>

                <div className="date-section">
                  <div className="date-item-list">
                    <div className="invoice-date">Issue Date</div>
                    <DatePicker
                      selected={this.state.invoiceDate}
                      onChange={date => {
                        this.props.invoiceDateChange(date);
                      }}
                      minDate={new Date()}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="MMMM d, yyyy"
                      className="invoice-control"
                      placeholderText="Invoice Date"
                      name="date"
                      autoComplete="off"
                      disabled={true}
                    />
                  </div>
                  {this.state.userPaidDate && (
                    <div className="date-item-list">
                      <div className="invoice-date">User Paid Date</div>
                      <DatePicker
                        selected={this.state.userPaidDate}
                        minDate={new Date()}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="MMMM d, yyyy"
                        className="invoice-control"
                        placeholderText="Invoice Date"
                        name="date"
                        autoComplete="off"
                        disabled={true}
                      />
                    </div>
                  )}
                  {this.state.invoiceDueDate && (
                    <div className="date-item-list">
                      <div className="invoice-date">Due Date</div>
                      <DatePicker
                        selected={this.state.invoiceDueDate}
                        minDate={new Date()}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="MMMM d, yyyy"
                        className="invoice-control"
                        placeholderText="Invoice Date"
                        name="date"
                        autoComplete="off"
                        disabled={true}
                      />
                    </div>
                  )}
                </div>

                <div className="invoice-billing-info-section">
                  <div className="invoice-bill-title">Bill To:</div>
                  <div className="invoice-bill-name">
                    {
                      // The API (back-end) sends a "customer" record back with all the receipt/order data.
                      // This "customer" record is usually a left join on the users table, which includes "displayName".
                      // But when customers_table==="partner_gift_customers" (ie. the order is for a gift card)
                      //  - the sender does not have to be an existing user, and hence no user record or "displayName"
                      //  - so we store the name they used, when ordering, in the "from" field of the "partner_gift_customers" table.
                      this.state.data
                        ? this.state.data.customers_table ===
                          "partner_gift_customers"
                          ? customer[0].from
                          : customer[0].displayName
                        : null
                    }
                  </div>
                  {this.state.isNewCustomer ? (
                    <div className="invoice-bill-address1">
                      {customer[0].email}
                    </div>
                  ) : (
                    <>
                      <div className="invoice-bill-address1">
                        {customer[0].address}
                      </div>
                      <div className="invoice-bill-address2">
                        {customer[0].city}
                        {customer[0].province ? `, ${customer[0].province}` : ""}
                        {customer[0].postalCode
                          ? `, ${customer[0].postalCode}`
                          : ""}
                      </div>
                      <div className="invoice-bill-address1">
                        {customer[0].email}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="invoice-receipt-table-container">
              <table className="order-receipt-table">
                <thead>
                  <tr className="order-receipt-header-section">
                    {this.headings?.map(heading => {
                      return (
                        <th
                          key={heading.label}
                          style={heading.style}
                          className="invoice-receipt-table-header"
                        >
                          {heading.label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody>
                  {this.state.bodyData?.map((row, rowIndex) => {
                    console.log("row", row);
                    return (
                      <tr key={rowIndex} className="order-receipt-item-section">
                        {row.map((cell, cellIndex) => {
                          if (cell.type === "action") {
                            return (
                              <td
                                key={cellIndex}
                                style={this.headings?.[cellIndex].style}
                                className={`invoice-receipt-item-menu ${this.headings?.[cellIndex].className || ""
                                  }`}
                              >
                                {cell.isAddDiscount ? (
                                  <i
                                    className="fas fa-ellipsis-v"
                                    style={{
                                      color: "#2639d2",
                                      marginRight: "20px",
                                      cursor: "pointer",
                                      position: "relative"
                                    }}
                                    onClick={event => {
                                      if (
                                        this.state.invoice_status ===
                                        "Cancelled"
                                      )
                                        return;
                                      this.setState({
                                        anchorEl: event.currentTarget,
                                        selectedItemIndex: cell.indexValue
                                      });
                                    }}
                                    aria-label="more"
                                    aria-controls="add-menu"
                                    aria-haspopup="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fas fa-ellipsis-v"
                                    style={{
                                      color: "#2639d2",
                                      marginRight: "20px",
                                      cursor: "pointer",
                                      position: "relative"
                                    }}
                                    onClick={event => {
                                      if (
                                        this.state.invoice_status ===
                                        "Cancelled"
                                      )
                                        return;
                                      this.setState({
                                        anchorE2: event.currentTarget,
                                        selectedItemIndex: cell.indexValue
                                      });
                                    }}
                                    aria-label="more"
                                    aria-controls="edit-menu"
                                    aria-haspopup="true"
                                  ></i>
                                )}
                                <Menu
                                  id="add-menu"
                                  anchorEl={this.state.anchorEl}
                                  keepMounted
                                  open={Boolean(this.state.anchorEl)}
                                  onClose={_ =>
                                    this.setState({ anchorEl: null })
                                  }
                                  PaperProps={{
                                    style: {
                                      // maxHeight: "45px",
                                      // width: '100%',
                                      minHeight: "30px",
                                      margin: 0,
                                      display: "flex",
                                      justifyContent: "center"
                                    }
                                  }}
                                >
                                  <MenuItem
                                    key="add-discount"
                                    onClick={_ => {
                                      this.setState({
                                        openAddDiscountModal: true,
                                        selectedItem: row,
                                        anchorEl: null
                                      });
                                    }}
                                  >
                                    Add Discount
                                  </MenuItem>
                                  <MenuItem
                                    key="edit-item"
                                    onClick={_ =>
                                      this.setState({
                                        openEditItemModal: true,
                                        selectedItem: row,
                                        anchorEl: null
                                      })
                                    }
                                  >
                                    Edit Item
                                  </MenuItem>
                                  <MenuItem
                                    key="remove-item"
                                    onClick={_ => this.removeItem(row)}
                                  >
                                    Remove Item
                                  </MenuItem>
                                </Menu>

                                <Menu
                                  id="edit-menu"
                                  anchorEl={this.state.anchorE2}
                                  keepMounted
                                  open={Boolean(this.state.anchorE2)}
                                  onClose={_ =>
                                    this.setState({ anchorE2: null })
                                  }
                                  PaperProps={{
                                    style: {
                                      // maxHeight: "45px",
                                      // width: '100%',
                                      minHeight: "30px",
                                      margin: 0,
                                      display: "flex",
                                      justifyContent: "center"
                                    }
                                  }}
                                >
                                  <MenuItem
                                    key="edit-discount"
                                    onClick={_ => {
                                      this.setState({
                                        openEditDiscountModal: true,
                                        selectedItem: row,
                                        anchorE2: null
                                      });
                                    }}
                                  >
                                    Edit Discount
                                  </MenuItem>
                                  <MenuItem
                                    key="edit-line-item"
                                    onClick={_ => {
                                      console.log("edit-line-item");
                                      this.setState({
                                        anchorE2: null,
                                        openEditItemModal: true,
                                        selectedItem: row
                                      });
                                    }}
                                  >
                                    Edit Item
                                  </MenuItem>
                                  <MenuItem
                                    key="remove-item"
                                    onClick={_ => {
                                      console.log("remove item");
                                      this.removeItem(row);
                                    }}
                                  >
                                    Remove Item
                                  </MenuItem>
                                </Menu>
                              </td>
                            );
                          }
                          return (
                            <td
                              key={cellIndex}
                              style={this.headings?.[cellIndex].style}
                              className={`invoice-receipt-item-content ${this.headings?.[cellIndex].className || ""
                                }`}
                            >
                              <span style={cell.style}>{cell.value}</span>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  {this.state.footData?.map((row, rowIndex) => {
                    return (
                      <tr
                        key={rowIndex}
                        className="invoice-receipt-footer-section"
                      >
                        {row.map((cell, cellIndex) => {
                          return (
                            <td
                              key={cellIndex}
                              style={this.headings?.[cellIndex].style}
                              className={`invoice-receipt-item-content ${this.headings?.[cellIndex].className || ""
                                }`}
                            >
                              <span style={cell.style}>{cell.value}</span>
                              {cell.value === "User Paid Total" &&
                                cell.additionalText === "" &&
                                this.state.data.type === "Recurring"
                                ? (cell.additionalText = `${this.state.data.product.transaction_cost
                                    ? ` (Inclusive of Credit Card ${this.state.data.product
                                      .transaction_cost
                                      ? "1.5%"
                                      : ""
                                    })`
                                    : ""
                                  }`)
                                : null}
                              {console.log("DATA TEXT", this.state.data)}
                              {cell.additionalText ? (
                                <span style={cell.additionalStyle}>
                                  {" "}
                                  {cell.additionalText}
                                </span>
                              ) : null}
                              {console.log("CELL TEXT", cell)}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tfoot>
              </table>
            </div>
            <div className="order-receipt-footer"></div>
          </div>

          <p
            style={{
              fontSize: "8px",
              textAlign: "center",
              fontFamily: "monospace",
              color: "transparent"
            }}
          >
            {this.state.referenceNumber}
          </p>

          <div
            style={{
              display: "flex",
              width: "80%",
              margin: "auto",
              marginBottom: "30px",
              justifyContent: "center"
            }}
          >
            <div
              className="add-item-btn"
              style={{
                background: "#b2b8c7",
                border: "1px solid #b2b8c7"
              }}
              onClick={_ => this.props.history.goBack()}
            >
              Back
            </div>
            {this.state.data?.product_table === "partner_party" && this.state.data?.status === null && this.state.data.type !== "Invoice" && (
              <div
                className="add-item-btn"
                style={{
                  background: "#2333ba",
                  border: "1px solid #b2b8c7"
                }}
                onClick={_ => {
                  this.setState({
                    openViewPartyAddonsModal: true
                  })
                }}
              >
                Edit Add-ons
              </div>
            )}
          </div>

          <div className="invoice-message-container">
            <div className="invoice-scheduled-date-section">
              <div className="invoice-schedule-note">
                <i className="far fa-clock" style={{ marginRight: "7px" }}></i>{" "}
                {this.state.invoice_status === "Issued"
                  ? "Sent on"
                  : "Scheduled for"}{" "}
                {days[getDay(new Date(this.state.invoiceDate))]}{" "}
                {format(new Date(this.state.invoiceDate), "LLLL dd, yyyy")}
              </div>
            </div>
            <div className="invoice-scheduled-message-section">
              <div className="invoice-message">{this.state.message}</div>
            </div>
          </div>

          {this.state.data?.next_invoice_date && (
            <div className="next-date-container invoice-scheduled-date-section">
              <div>
                <i className="far fa-clock" style={{ marginRight: "7px" }}></i>{" "}
                Next invoice:{" "}
              </div>
              <div style={{ flexGrow: "1", marginLeft: "10px" }}>
                {this.state.editNextDate ? (
                  <DatePicker
                    selected={this.state.next_invoice_date}
                    onChange={date => {
                      console.log(date);
                      this.setState(
                        { next_invoice_date: date, editNextDate: false },
                        () => this.updateInvoiceDate()
                      );
                    }}
                    minDate={minNextDate}
                    showYearDropdown
                    showMonthDropdown
                    // dateFormat="MMMM d, yyyy"
                    className="next-control"
                    placeholderText="Service Period"
                    disabled={!this.state.editNextDate}
                  />
                ) : (
                  format(
                    new Date(this.state.data.next_invoice_date),
                    "LLLL dd, yyyy"
                  )
                )}
                <span style={{ fontStyle: "italic" }}>
                  {" (will be due on " +
                    format(this.getNextDueDate(), "LLLL dd, yyyy") +
                    ")"}
                </span>
              </div>
              {isAfter(
                new Date(this.state.data.next_invoice_date),
                new Date()
              ) &&
                (!this.state.data.stripe_transaction_id || !this.state.data.stripe_transaction_id.includes("sub_")) && (
                  <IconButton
                    onClick={e =>
                      this.setState({ editNextDate: !this.state.editNextDate })
                    }
                    style={{ padding: "8px" }}
                  >
                    {this.state.editNextDate ? (
                      <Close style={{ color: "white" }} />
                    ) : (
                      <Edit style={{ color: "white" }} />
                    )}
                  </IconButton>
                )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(NewInvoiceView);
